import { useSpring, animated } from 'react-spring';
import { useState } from 'react';

function BounceAnimation({ options, children }) {
    const [isBouncing, setIsBouncing] = useState(true);

    const {
        preDelay = 0,
        duration = 1000,
        bounceHeight = 200
    } = options;

    const bounce = useSpring({
        transform: isBouncing ? 'translateY(0px)' : `translateY(-${bounceHeight}px)`,
        config: {
            tension: 180,
            friction: 12,
            mass: 1,
            duration: duration,
        },
        onRest: () => setIsBouncing(false),
        delay: preDelay
    });

    return (
        <animated.div style={bounce}>
            {children}
        </animated.div>
    );
}

export default BounceAnimation;