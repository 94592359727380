
import Form from 'react-bootstrap/Form';
import useComponentState from "src/hooks/state/useComponentState";

function TextInputDefault(props: any) {
    const [componentState, updateComponentState] = useComponentState(props.id, {
        value: '',
        ...props.varibles
    });

    return (
        <>
            <Form.Label htmlFor={props.id}>{props.label}</Form.Label>
            <Form.Control
                type="text"
                value={componentState.value}
                onChange={(e: any) => updateComponentState({ value: e.target.value })}
                id={props.id}
                required={props.required || false}
            />
        </>
    );  
}

export default TextInputDefault