import axios from 'axios';
import Executor from 'src/app/ActionFlow/Executor';
import { executeAction } from 'src/utils/actionHanlders';
import { resolveContextData } from 'src/utils';
import _ from 'lodash';
import { Operators } from 'src/app/operators';

const updateInContext = (response: any, actionId: string, executor?: Executor) => {

    if(!response) return;

    executor && executor.getHandler().setActionData(actionId, {
        responseData : response.data,
        responseStatus : response.status,
        responseHeaders : response.headers,
        responseStatusText : response.statusText
    });
}

const handleSuccess = (response: any, config: any, executor?: Executor) => {
    updateInContext(response, config.id, executor);

    let success = true;

    if (config.successCondition) {
        config.successCondition = resolveContextData(config.successCondition, executor?.getHandler());
        // eslint-disable-next-line
        success = new Function('Operators',`return ${config.successCondition};`)(Operators);
    }

    if (success) {
        if (config.onSuccess) {
            _.each(config.onSuccess, (action: any) => {
                executeAction(action, executor);
            });
        }
    } else {
        // If the success condition fails, treat it as an error
        handleError(response, config, executor);
    }
}

const handleError = (response: any, config: any, executor?: Executor) => {
    updateInContext(response, config.id, executor);

    if(config.onError) {
        _.each(config.onError, (action: any) => {
            executeAction(action, executor);
        });
    }
}

const handleComplete = (config: any, executor?: Executor) => {
    if(config.onComplete) {
        _.each(config.onComplete, (action: any) => {
            executeAction(action, executor);
        });
    }
}

const PerformApiCall = async (config: any, executor?: Executor) => {

    if(executor) config = await resolveContextData(config, executor?.getHandler(), {
        skipKeys : ['onSuccess', 'onFailure', 'onComplete', 'successCondition']
    });

    const request = {
        method: config.method ?? 'GET',
        url : config.url,
    }

    if(config.responseType) request['responseType'] = config.responseType;
    if(config.requestBody){
        if(typeof config.requestBody === 'string') request['data'] = JSON.parse(config.requestBody);
        if(typeof config.requestBody === 'object') request['data'] = config.requestBody;
    };

    if(config.requestParams) request['params'] = config.requestParams;
    if(config.headers) request['headers'] = config.headers;
    if(config.withCredentials) request['withCredentials'] = config.withCredentials;

    axios(request).then((response: any) => {
        handleSuccess(response, config, executor);
    }).catch((error: any) => {
        handleError(error.response, config, executor);
    }).finally(() => {
        handleComplete(config, executor);
    })
};

export default PerformApiCall;