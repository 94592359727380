import {createGlobalStyle} from 'styled-components';

function buildTheme(projectConfig) {
    const themeConfig = projectConfig.styling;

    const GlobalStyle = createGlobalStyle`
        :root {
            --theme-color: ${themeConfig.themeColor || '#000000'};
            --body-color: ${themeConfig.bodyColor || '#FFFFFF'};
            --body-bg: ${themeConfig.bodyBg || '#000000'};
            --color-primary: ${themeConfig.colorPrimary || '#007bff'};
            --color-primary-dark: ${themeConfig.colorPrimaryDark || '#0056b3'};
            --color-primary-light: ${themeConfig.colorPrimaryLight || '#66b3ff'};
            --color-primary-contrast-text: ${themeConfig.colorPrimaryContrastText || '#FFFFFF'};
            --color-secondary: ${themeConfig.colorSecondary || '#6c757d'};
            --color-secondary-dark: ${themeConfig.colorSecondaryDark || '#495057'};
            --color-secondary-light: ${themeConfig.colorSecondaryLight || '#a9aeb1'};
            --color-secondary-contrast-text: ${themeConfig.colorSecondaryContrastText || '#FFFFFF'};
            --color-success: ${themeConfig.colorSuccess || '#28a745'};
            --color-success-dark: ${themeConfig.colorSuccessDark || '#218838'};
            --color-success-light: ${themeConfig.colorSuccessLight || '#71dd8a'};
            --color-success-contrast-text: ${themeConfig.colorSuccessContrastText || '#FFFFFF'};
            --color-danger: ${themeConfig.colorDanger || '#dc3545'};
            --color-danger-dark: ${themeConfig.colorDangerDark || '#c82333'};
            --color-danger-light: ${themeConfig.colorDangerLight || '#f5b5b9'};
            --color-danger-contrast-text: ${themeConfig.colorDangerContrastText || '#FFFFFF'};
            --color-warning: ${themeConfig.colorWarning || '#ffc107'};
            --color-warning-dark: ${themeConfig.colorWarningDark || '#e0a800'};
            --color-warning-light: ${themeConfig.colorWarningLight || '#ffe899'};
            --color-warning-contrast-text: ${themeConfig.colorWarningContrastText || '#000000'};
            --color-info: ${themeConfig.colorInfo || '#17a2b8'};
            --color-info-dark: ${themeConfig.colorInfoDark || '#117a8b'};
            --color-info-light: ${themeConfig.colorInfoLight || '#75d6e0'};
            --color-info-contrast-text: ${themeConfig.colorInfoContrastText || '#FFFFFF'};
            --color-light: ${themeConfig.colorLight || '#f8f9fa'};
            --color-light-dark: ${themeConfig.colorLightDark || '#d1d3d4'};
            --color-light-light: ${themeConfig.colorLightLight || '#ffffff'};
            --color-light-contrast-text: ${themeConfig.colorLightContrastText || '#000000'};
            --color-dark: ${themeConfig.colorDark || '#343a40'};
            --color-dark-dark: ${themeConfig.colorDarkDark || '#1d2124'};
            --color-dark-light: ${themeConfig.colorDarkLight || '#5b6266'};
            --color-dark-contrast-text: ${themeConfig.colorDarkContrastText || '#FFFFFF'};
            --color-muted: ${themeConfig.colorMuted || '#6c757d'};
            --color-muted-dark: ${themeConfig.colorMutedDark || '#4e555b'};
            --color-muted-light: ${themeConfig.colorMutedLight || '#aeb1b5'};
            --color-muted-contrast-text: ${themeConfig.colorMutedContrastText || '#FFFFFF'};
            --link-color: ${themeConfig.linkColor || '#007bff'};
            --link-hover-color: ${themeConfig.linkHoverColor || '#0056b3'};
            --background-color: ${themeConfig.backgroundColor || '#f8f9fa'};
            --background-color-dark: ${themeConfig.backgroundColorDark || '#e9ecef'};
            --background-color-light: ${themeConfig.backgroundColorLight || '#ffffff'};
            --border-color: ${themeConfig.borderColor || '#dee2e6'};
            --border-color-dark: ${themeConfig.borderColorDark || '#c4c9cc'};
            --border-color-light: ${themeConfig.borderColorLight || '#f1f3f5'};
            --font-family: ${themeConfig.fontFamily || 'Open Sans, sans-serif'};
            --font-size: ${themeConfig.fontSize || '14px'};
        }
    `;

    return GlobalStyle;
};

export default buildTheme;