import { useEffect, useRef } from 'react';

const componentRefs = {};

const useComponentRef = (id: string) => {
    const ref = useRef(null);

    useEffect(() => {

        componentRefs[id] = ref;

        return () => {
            delete componentRefs[id];
        };
    }, [id]);

    return ref;
};

export { componentRefs };

export default useComponentRef;