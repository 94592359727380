import { resolveComponentData } from "src/utils";
import { NavLink } from "react-router-dom";

function MenuLinkDefault(props: any) {

    props = resolveComponentData(props, ['items']);

    return (
        <NavLink
            to={props.link || '#'}
            style={({ isActive }) => ({
                ...props.styles,
                ...props.itemStyles,
                ...(isActive ? props.activeItemStyles : {})
            })}>
            {props.label}
        </NavLink>
    );
}

export default MenuLinkDefault;