const semiCircle = (dimensions: any) => {
    const { posX, posY, width  : containerWidth, n, tiltAlongPath,  height: containerHeight } = dimensions;

    // Find center point of the container in actual coordinates
    const radius = (containerWidth - 50) * 0.3;

    const centerX = posX + containerWidth / 2;
    const centerY = posY + containerHeight / 2;

    const toScreenCoords = (x, y) => ({
        x: x + centerX,
        y: centerY - y
    });

    const points = [];

    for (let i = 0; i < n; i++) {
        const t = (i / n) * Math.PI; // Angle range for semi-circle
        const startAngle = 150 * Math.PI / 180;

        const angle = startAngle - t;
        const mathX = radius * Math.cos(angle);
        const mathY = radius * Math.sin(angle);

        // Convert to screen coordinates
        const screenPos = toScreenCoords(mathX, mathY);

        if (!tiltAlongPath) {
            points.push({
                x: screenPos.x,
                y: screenPos.y,
                rotation: 0
            });
            continue;
        }

        // Calculate rotation angle using the derivative of parametric equations
        const dx = radius * Math.sin(angle);  // Note: negative sign absorbed in angle calculation
        const dy = radius * Math.cos(angle);
        let rotation = Math.atan2(dy, dx);

        // Convert to degrees and adjust for screen coordinates
        rotation = (rotation * 180 / Math.PI);

        const isBottomHalf = screenPos.y > centerY;
        const isRightHalf = screenPos.x > centerX;

        if (isBottomHalf) {
            if (rotation < 0 && !isRightHalf) {
                rotation = 180 + rotation;
            } else if (rotation > 0 && isRightHalf) {
                rotation = rotation - 180;
            } else {
                rotation = 180 - rotation;
            }
        }

        points.push({
            x: screenPos.x,
            y: screenPos.y,
            rotation: rotation
        });
    }

    console.log(points);

    return points;
}

export default semiCircle;