import { useSpring, animated } from 'react-spring';

const FadeAnimation = ( {options, children }) => {
    const fadeSpring = useSpring({
        from: { opacity: 0},
        to: { opacity: 1},
        delay: options.preDelay || 0,
        config: { duration: options.duration || 1000 }
    });

    return (
        <animated.div style={fadeSpring}>
            {children}
        </animated.div>
    );
}

export default FadeAnimation;