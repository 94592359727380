
import Executor from "src/app/ActionFlow/Executor";
import { resolveContextData } from "src/utils";
import store from "../state/store";
import { setState } from "../state/componentState/componentStateSlice";

const ApplyAnimation = async (config: any, executor?: Executor) => {

    if (!config.componentId) return;

    if (executor) config = resolveContextData(config, executor?.getHandler());

    store.dispatch(setState({
        id: config.componentId,
        data: {
            animation: {
                type: config.type,
                options: {
                    ...config.options
                }
            }

        }
    }));
}

export default ApplyAnimation;