import { useSpring, animated } from "react-spring";
import { CSSProperties, useRef, useState } from "react";

function ScrollingAnimation({ options, children }) {
    const {
        scrollDirection = 'left',
        speed = 15000,
    } = options;

    const containerRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const [items, setItems] = useState([0, 1]); // Start with 2 copies
    const lastId = useRef(1);

    const isVertical = scrollDirection === 'up' || scrollDirection === 'down';
    const isReverse = scrollDirection === 'right' || scrollDirection === 'down';

    const containerStyle: CSSProperties = {
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
        height: '100%',
    };

    const contentStyle: CSSProperties = {
        display: 'flex',
        flexDirection: isVertical ? 'column' : 'row',
        width: isVertical ? '100%' : 'fit-content',
        height: isVertical ? 'fit-content' : '100%',
    };

    const itemStyle: CSSProperties = {
        display: 'inline-flex',
        flexShrink: 0,
    };

    const getTransform = (value: any) => {
        const percentage = isReverse ? -value : value;
        return isVertical
            ? `translateY(${percentage}%)`
            : `translateX(${percentage}%)`;
    };

    const childDiv = <div style={itemStyle}>
        {children}
    </div>;

    const scrollSpring = useSpring({
        from: { value: 0 },
        to: { value: -100 },
        loop: true,
        config: {
            duration: speed,
            tension: 280,
            friction: 30,
        },
        // onChange: (_, ctrl) => {
        //     checkPositions(ctrl.get().value);
        // }
    });

    // const checkPositions = (currentValue: number) => {
    //     if (!containerRef.current || !contentRef.current) return;

    //     const container = containerRef.current.getBoundingClientRect();
    //     const children = contentRef.current.children;

    //     // Check if we need to add new div
    //     const lastChild = children[children.length - 1].getBoundingClientRect();
    //     const secondLastChild = children[children.length - 2].getBoundingClientRect();

    //     console.log(container, lastChild, secondLastChild);

    //     if (isVertical) {
    //         // if (secondLastChild.bottom < container.bottom) {
    //         //     lastId.current += 1;
    //         //     setItems(prev => [...prev, lastId.current]);
    //         // }
    //         // // Remove first div when it's completely out of view
    //         // if (children[0].getBoundingClientRect().bottom < container.top) {
    //         //     setItems(prev => prev.slice(1));
    //         // }
    //     } else {
    //         if (secondLastChild.right <= container.right - 300) {
    //             lastId.current += 1;
    //             setItems(prev => [...prev, lastId.current]);
    //         }

    //         if (children[0].getBoundingClientRect().right < container.left) {
    //             setItems(prev => prev.slice(1));
    //         }
    //     }
    // };

    return (
        <div style={containerStyle} ref={containerRef} >
            <animated.div
                style={{
                    ...contentStyle,
                    transform: scrollSpring.value.to(getTransform),
                }} ref={contentRef}
            >
                {items.map(id => (
                    <div key={id} style={itemStyle}>
                        {children}
                    </div>
                ))}
            </animated.div>
        </div>
    );
}

export default ScrollingAnimation;