import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface payloadType {
    id: string;
    data?: {};
}

const componentStateSlice = createSlice({
    name: 'componentState',
    initialState: {},
    reducers: {
        setState: (state, action: PayloadAction<payloadType>) => {
            const {id,data} = action.payload;
            state[id] = {...state[id],...data};
        },
        deleteState: (state, action : PayloadAction<payloadType>) => {
            const {id}=action.payload;
            delete state[id];
        }
    },
});

export const { setState, deleteState } = componentStateSlice.actions;

export default componentStateSlice.reducer;