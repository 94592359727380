
import { Button } from '@mui/material';
import { resolveComponentData } from "src/utils";
import { darken } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import _ from "lodash";

function ButtonShadow(props: any) {

    props = resolveComponentData(props);

    const theme = useTheme();
    const btnColor = props.btnColor || 'primary'; 
    
    const buttonBgColor = theme.palette[btnColor]?.main || theme.palette.primary.main;
    const buttonDarkColor = darken(buttonBgColor, 0.2);
    const buttonTextColor = theme.palette[btnColor]?.contrastText || theme.palette.primary.contrastText;

    const isOutlined = props.btnClass === 'outlined';

    return (
        <Button
            sx={_.merge({}, props.styles || {}, {
                border: isOutlined ? `1px solid ${buttonBgColor}` : 'none', // Border for outlined variant
                outlineOffset: '4px',
                borderRadius: '0.375rem',
                background: buttonDarkColor,
                color: isOutlined ? buttonBgColor : buttonTextColor,
                padding: 0
            })}
            onClick={props.onClick || undefined}
            variant={props.btnClass || 'contained'}
            fullWidth={props.fullWidth || false}
            disabled={props.disabled || false}
            type={props.btnType || 'button'}
        >
            <span style={{
                background: isOutlined ? 'white' : buttonBgColor,
                color: isOutlined ? buttonBgColor : buttonTextColor,
                transform: 'translateY(-6px)',
                borderRadius: '0.375rem',
                padding: '6px 16px',
                border: isOutlined ? `1px solid ${buttonBgColor}` : 'none',
            }}>
                {props.text}
            </span>
        </Button >
    );
}

export default ButtonShadow