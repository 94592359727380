import FadeAnimation from './Fade';
import TypingAnimation from './Typing'
import SlideAnimation from './Slide';
import BounceAnimation from './Bounce';
import SpreadAnimation from './spread';
import ScrollingAnimation from './Scrolling';
import BackgroundColorFillAnimation from './BackgroundColorFill';


const animations = {
    typing: TypingAnimation,
    fade: FadeAnimation,
    slide: SlideAnimation,
    bounce: BounceAnimation,
    spread: SpreadAnimation,
    scrolling: ScrollingAnimation,
    backgroundFill: BackgroundColorFillAnimation
};

export const getAnimation = (type: string) => {
    return animations[type];
};