import { actions } from '../actions';
import Executor from 'src/app/ActionFlow/Executor';
import { resolveComponentData } from '.';

const loadActionFunction = async (actionName: string) => {
    if (actions[actionName]) {
        return actions[actionName];
    }
    throw new Error(`Action ${actionName} not found`);
};

const executeAction = async (actionConfig: any, executor?: Executor) => {

    actionConfig = await resolveComponentData(actionConfig, ['action']);
    
    const { action, ...actionProps } = actionConfig;
    const actionHandler = await loadActionFunction(action);

    executor && executor.getHandler().setCurrentAction(actionProps.id);

    try {
        if ('function' === typeof actionHandler) return await actionHandler(actionProps, executor);

        if (typeof actionHandler === 'object' && actionHandler.prototype?.execute) {
            const instance = new actionHandler(
                actionProps,
                executor
            );
            return await instance.execute();
        }

    } catch (error) {
        executor?.getHandler().setStatusAsError('Error while executing action. ' + error);
    }
};

const eventsHandler = async (events: any) => {
    for (const event of events) {
        if (event.action === "executeActionFlow") {
            console.log('Executing action flow', event.id);
            const flowExecutor = new Executor(event.id);
            await flowExecutor.executeFlow();
        } else {
            executeAction(event);
        }
    }
};

export { executeAction, eventsHandler };