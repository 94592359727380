
import { Box, TextField } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

function SearchDefault() {
    return (
        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <SearchOutlinedIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
            <TextField id="input-with-sx" label="With sx" variant="standard" />
        </Box>
    );
}

export default SearchDefault;