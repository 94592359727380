import AbstractDataHandler, { DataAndReturnPathType } from "src/app/DataHandlers/AbstractDataHandler";

class DataHandler implements AbstractDataHandler {
    private data: any

    constructor(data: any) {
        this.data = data
    }
    getDataAndRestPath(path: string): DataAndReturnPathType {
        return {
            data: this.data,
            restPath: path
        }
    }
}

export default DataHandler;