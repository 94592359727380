
import { getAnimation } from '../index';

const AnimatedComponent = ({config: { type, options = {} }, children}) => {
    const Animation = getAnimation(type);

    if(!Animation) return children;

    return (
        <Animation options={options}>
            {children}
        </Animation>
    )
}

export default AnimatedComponent;