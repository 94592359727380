import { useSpring, animated } from 'react-spring';
import { useEffect, useRef, useState, useCallback, cloneElement } from 'react';

const TypingAnimation = ({ options, children }) => {

    const [displayedContent, setDisplayedContent] = useState('');
    const originalContent = useRef('');
    const isAnimatingRef = useRef(false);

    const {
        speed = 50,
        preDelay = 0,
        text,
        showCursor = true,
    } = options;

    // Extract text content from children
    useEffect(() => {
        if (text) {
            originalContent.current = text;
            setDisplayedContent('');
            startAnimation();
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [text]);

    const cursorSpring = useSpring({
        from: { opacity: 1 },
        to: async (next) => {
            while (showCursor) {
                await next({ opacity: 0.8 });
                await next({ opacity: 1 });
            }
        },
        config: { duration: 500 },
    });

    const startAnimation = useCallback(async () => {
        isAnimatingRef.current = true;
        const content = originalContent.current;

        await new Promise(resolve => setTimeout(resolve, preDelay));

        for (let i = 0; i < content.length; i++) {
            if (!isAnimatingRef.current) break;

            await new Promise(resolve => {
                setTimeout(() => {
                    setDisplayedContent(content.slice(0, i + 1));
                    resolve(null);
                }, speed);
            });
        }
    }, [speed, preDelay]);

    // Cleanup on unmount
    useEffect(() => {
        return () => {
            isAnimatingRef.current = false;
        };
    }, []);

    return (
        <animated.div>
            {cloneElement(children, {
                children: (
                    <>
                        {displayedContent}
                        {showCursor && <animated.span style={cursorSpring}>|</animated.span>}
                    </>
                ),
            })}
        </animated.div>
    );
};

export default TypingAnimation;