import { NavigateFunction } from 'react-router-dom';
import Executor from 'src/app/ActionFlow/Executor';

// Option 1: Using a global navigator instance
let navigate: NavigateFunction;

const setNavigator = (nav: NavigateFunction) => {
    navigate = nav;
};

const Navigate = (config: any, executor?: Executor) => {
    if (!navigate) {
        console.error('Navigation not initialized. Make sure to call setNavigator first.');
        return;
    }

    navigate(config.route, {
        replace: config.replace || true,
    });
};

export { Navigate, setNavigator };