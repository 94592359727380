
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

const userSlice = createSlice({
    name: 'user',
    initialState: {
        data: {},
        token: window.localStorage.getItem('token') || null,
        isAuthenticated: window.localStorage.getItem('token') ? true : false
    },
    reducers: {
        setUserData: (state, action : PayloadAction<string>) => {
            state.data = action.payload
        },

        updateUserData: (state, action : PayloadAction<any>) => {
            state.data = _.merge({}, state.data, action.payload)
        },

        setToken: (state, action : PayloadAction<string>) => {
            localStorage.setItem('token', action.payload);

            state.token = action.payload;
            state.isAuthenticated = true;
        },

        setAuthenticated: (state, action : PayloadAction<boolean>) => {
            state.isAuthenticated = action.payload;
        }
    },
});

export const { setUserData, updateUserData, setToken, setAuthenticated } = userSlice.actions;
export default userSlice.reducer;