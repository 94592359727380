import AbstractDataHandler from "./DataHandlers/AbstractDataHandler";

type ResolverOptions = {
    skipKeys?: string[];
};

/**
 * Resolves a value that might contain placeholders in the json
 */
class DataResolver {

    private handler: AbstractDataHandler;
    private placeholderRegex = /\${([^}]+)}/g;
    private skipKeys: Set<string>;

    constructor(
        handler: AbstractDataHandler,
        options: ResolverOptions = {}
    ) {
        this.handler = handler;
        this.skipKeys = new Set(options.skipKeys || []);
    }

    /**
     * Checks if a key should be skipped for replacement
     */
    private shouldSkipKey(key: string): boolean {
        return this.skipKeys.has(key);
    }

    /**
     * Resolves a value that might contain placeholders
     */
    resolveData(value: any, currentPath: string[] = []): any {
        // Check if current path should be skipped
        const currentKey = currentPath[currentPath.length - 1];
        if (currentKey && this.shouldSkipKey(currentKey)) {
            return value;
        }

        if (typeof value === 'string') {
            return this.resolvePlaceholders(value);
        } else if (Array.isArray(value)) {
            return value.map((item, index) =>
                this.resolveData(item, [...currentPath, `${index}`])
            );
        } else if (value && typeof value === 'object') {
            return this.resolveObject(value, currentPath);
        }
        return value;
    }

    /**
     * Resolves placeholders in a string
     */
    private resolvePlaceholders(str: string): any {
        // If the entire string is a placeholder, return the direct value
        if (str.startsWith('${') && str.endsWith('}')) {
            const path = str.slice(2, -1);
            const value = this.resolvePathValue(path);
            return value !== undefined ? value : str;
        }

        // Replace multiple placeholders in a string
        return str.replace(this.placeholderRegex, (match, path) => {
            const value = this.resolvePathValue(path);
            return value !== undefined
                ? typeof value === 'object' ? JSON.stringify(value) : String(value)
                : match;
        });
    }

    /**
     * Resolves an object with possible placeholder values
     */
    private resolveObject(obj: { [key: string]: any }, currentPath: string[] = []): { [key: string]: any } {
        const resolved: { [key: string]: any } = {};

        for (const [key, value] of Object.entries(obj)) {
            const newPath = [...currentPath, key];
            resolved[key] = this.resolveData(value, newPath);
        }

        return resolved;
    }

    /**
     * Resolves a path to its value from context or component state
     */
    private resolvePathValue(path: string): any {
        // Handle special paths
        const { data, restPath } = this.handler.getDataAndRestPath(path);

        if (data) {
            return this.getNestedValue(data, restPath);
        }

        return undefined;
    }

    /**
     * Gets a nested value from an object using a path string
     */
    private getNestedValue(obj: any, path: string): any {
        try {
            return path.split('.').reduce((current, part) => {
                // Handle array indexing
                if (part.includes('[') && part.includes(']')) {
                    const [arrayName, indexStr] = part.split('[');
                    const index = parseInt(indexStr.replace(']', ''));
                    return current[arrayName][index];
                }
                return current[part];
            }, obj);
        } catch (error) {
            console.warn(`Failed to resolve path: ${path}`, error);
            return undefined;
        }
    }
}

export default DataResolver;
export type { ResolverOptions };