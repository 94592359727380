
import useComponentState from "src/hooks/state/useComponentState";
import ComponentRenderer from "src/components/ComponentRenderer";
import { resolveComponentData } from "src/utils";
import { useEffect } from 'react';
import { eventsHandler } from "src/utils/actionHanlders";
import { Box, Grid2 as Grid } from "@mui/material";
import _ from "lodash";
import DataResolver from "src/app/DataResolver";
import DataHandler from "./data/DataHandler";
import AnimatedComponent from "src/animations/core/AnimatedComponent";

function LisitingDefault(props: any) {
    props = resolveComponentData(props, ['display', 'itemComponent', 'gridOptions']);

    // eslint-disable-next-line
    const [componentState, updateComponentState] = useComponentState(props.id, {
        data: [],
        ...props.variables
    });

    (props.events && props.events.beforeRender) && eventsHandler(props.events.beforeRender);

    useEffect(() => {
        (props.events && props.events.afterRender) && eventsHandler(props.events.beforeRender);
    }, []);


    if (componentState.data.length === 0) {
        return null;
    }

    const resolveLisitingComponentData = (itemComponent: any, item: any) => {
        const resolver = new DataResolver(new DataHandler(item));
        return resolver.resolveData(itemComponent);
    };

    if (props.display === 'grid') {
        const size = 12 / props?.gridOptions?.columns || 3;

        return (
            <Box component="section">
                <Grid container spacing={props?.gridOptions?.spacing || 2} sx={props.styles || {}}>
                    {_.map(componentState.data, (item: any, index: number) => (
                        <Grid key={'listing-item-' + index} size={size}>
                            <ComponentRenderer components={[resolveLisitingComponentData(props.itemComponent, item)]} />
                        </Grid>
                    ))}
                </Grid>
            </Box>
        )
    }

    if (props.display === 'animated') {

        const animationConfig = { ...props.animation };

        return (
            <Box component="section" sx={{ padding: 2 }}>
                <AnimatedComponent config={animationConfig}>
                    {_.map(componentState.data, (item: any, index: number) => (
                        <ComponentRenderer key={'listing-item-' + index} components={[resolveLisitingComponentData(props.itemComponent, item)]} />
                    ))}
                </AnimatedComponent>
            </Box>

        );
    }

    return (
        <>
        </>
    )
}

export default LisitingDefault