import { Box, Grid2 as Grid } from "@mui/material";
import ComponentRenderer from "../../ComponentRenderer";
import _ from "lodash";
import { resolveComponentData } from "src/utils";
import useComponentState from "src/hooks/state/useComponentState";
import AnimatedComponent from "src/animations/core/AnimatedComponent";
import { getComponentState } from "src/utils";
import { eventsHandler } from "src/utils/actionHanlders";

function FormDefault(props: any) {

    props = resolveComponentData(props, ['components']);

    const [componentState, updateComponentState] = useComponentState(props.id, {
        ...props.variables,
        components: props.components.map((component: any) => {
            return component.id;
        }),
        formData: {}
    });

    const handleSubmit = (event: any) => {
        event.preventDefault();
        const formData = componentState.components.reduce((acc: Record<string, any>, componentId: any) => {
            const childComponentState = getComponentState(componentId);

            // Only add the component if it has a defined value
            if (childComponentState &&childComponentState.value !== undefined) {
                return _.merge(acc, { [childComponentState.name]: childComponentState.value || '' });
            }

            return acc;
        }, {} as Record<string, any>);

        updateComponentState({ formData });

        (props.events && props.events.onSubmit) && eventsHandler(props.events.onSubmit);
    }

    if (undefined !== props.animation && props.animation.type !== 'typing') {
        const animationConfig = { ...props.animation };

        return (
            <AnimatedComponent config={animationConfig}>
                <Box component="form" onSubmit={handleSubmit}>
                    <Grid container spacing={2} sx={props.styles || {}}>
                        {_.map(props.components, (component: any, index: number) => (
                            <Grid key={component.id + '-col-' + index} size={component.span} sx={component.styles || {}}>
                                <ComponentRenderer components={[component]} />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </AnimatedComponent>
        );
    }

    return (
        <Box component="form" onSubmit={handleSubmit}>
            <Grid container spacing={2} sx={props.styles || {}}>
                {_.map(props.components, (component: any, index: number) => (
                    <Grid key={component.id + '-col-' + index} size={component.span} sx={component.styles || {}}>
                        <ComponentRenderer components={[component]} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default FormDefault;