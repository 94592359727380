import { colors, Typography } from "@mui/material";
import { resolveComponentData } from "src/utils";
import { Link } from 'react-router-dom';
import AnimatedComponent from "src/animations/core/AnimatedComponent";
import { useEffect, useState } from "react";
import useComponentState from "src/hooks/state/useComponentState";
import Icon from "@mui/material/Icon";

function TextDefault(props: any) {

    props = resolveComponentData(props, ['textType', 'link']);

    const [element, setElement] = useState(null);

    //eslint-disable-next-line
    const [componentState, updateComponentState] = useComponentState(props.id, {
        animation: props.animation || null,
        text: props.text || '',
        iconName: props.icon || null
    });

    // const [Icon, setIcon] = useState(null);

    useEffect(() => {
        if (componentState.iconName === null) return;

        const loadIcon = async () => {

            console.log('Loading icon:', componentState.iconName);
            // try {
            //     const icon = await import(`@mui/icons-material/${componentState.iconName}`);

            //     console.log('Icon:', icon.default);
            //     // setIcon(icon.default);
            // } catch (error) {
            //     console.error('Error loading icon:', error);
            // }
        };
        loadIcon();
    }, [componentState.icon]);

    useEffect(() => {
        if (props.link === true) {
            if (props.linkPart) {
                const parts = componentState.text.split(props.linkPart);
                setElement(
                    <>
                        {props.icon && <Icon sx={{ marginRight: componentState.text ? "3px" : "0px" }}></Icon>}
                        {parts[0]}
                        <Link to={props.route}>{props.linkPart}</Link>
                        {parts[1]}
                    </>
                );

            } else {
                setElement(
                    <Link style={{ color: "inherit" }} to={props.route}>{props.icon && <Icon sx={{ marginRight: componentState.text ? "3px" : "0px" }}></Icon>}{componentState.text}</Link>
                );
            }
        } else {
            setElement(props.text);
        }
    }, [componentState.text, props.link, props.linkPart, props.route]);

    if (componentState.animation !== null) {

        const animationConfig = { ...componentState.animation };

        if (animationConfig.type === 'typing') {
            animationConfig.options = { ...animationConfig.options, text: componentState.text };
        }

        return (
            <AnimatedComponent config={animationConfig}>
                <Typography variant={props.textType || 'body1'} sx={props.styles || {}}>
                    {element}
                </Typography>
            </AnimatedComponent>
        );
    }

    return (
        <Typography variant={props.textType || 'body1'} sx={props.styles || {}}>
            {element}
        </Typography>
    );
}

export default TextDefault;