import useComponentState from "src/hooks/state/useComponentState";
import { Backdrop, CircularProgress } from "@mui/material";

const BackdropDefault = () => {
    // eslint-disable-next-line
    const [componentState, updateComponentState] = useComponentState('MaterialBackdropDefault', {
        open: false
    });

    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={componentState.open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};

export default BackdropDefault;