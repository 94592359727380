import { createBrowserRouter, Navigate, RouteObject } from "react-router-dom";
import App from "../layout/App";
import routes from "../data/routes.json";
import _ from "lodash";
import { getGlobalState } from "src/utils";

const AuthGuard = ({ children, auth, redirectPath }: {
    children: JSX.Element,
    auth: boolean,
    redirectPath: string
}) => {
    const isAuthenticated = getGlobalState('user').isAuthenticated;

    if (auth && !isAuthenticated) {
        return <Navigate to={routes.config.loginRoute} replace />;
    }

    if (!auth && isAuthenticated && redirectPath) {
        return <Navigate to={redirectPath} replace />;
    }

    return children;
};

// Create route objects including aliases
const createRouteObjects = (routes: any): RouteObject[] => {
    const routeObjects: RouteObject[] = [];

    _.forEach(routes.data, (route: any, screenId: string) => {
        // Create main route
        const mainRoute: RouteObject = {
            path: route.path,
            element: (
                <AuthGuard
                    auth={!!route.userAuth}
                    redirectPath={route.redirectIfAuthenticated || ''}
                >
                    <App screenId={screenId} />
                </AuthGuard>
            )
        };
        routeObjects.push(mainRoute);

        // Create alias routes if they exist
        if (route.aliasPaths) {
            route.aliasPaths.forEach(aliasPath => {
                routeObjects.push({
                    path: aliasPath,
                    element: (
                        <AuthGuard
                            auth={!!route.userAuth}
                            redirectPath={route.redirectIfAuthenticated || ''}
                        >
                            <App screenId={screenId} />
                        </AuthGuard>
                    )
                });
            });
        }
    });

    // Add catch-all route
    routeObjects.push({
        path: '*',
        element: <Navigate to={routes.config.defaultRoute} replace />
    });

    return routeObjects;
};

const router = createBrowserRouter(createRouteObjects(routes));

const routesConfig = {
    ...routes.config
};

export {
    router,
    routesConfig
};