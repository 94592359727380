import { resolveComponentData } from "src/utils";
import { Box, Grid2 as Grid } from "@mui/material";
import ComponentRenderer from "../../ComponentRenderer";

function MenuDefault(props: any) {

    props = resolveComponentData(props, ['items']);

    return (
        <Box sx={props.styles || {}}>
            <Grid container spacing={props.itemSpace ?? 5} justifyContent="center">
                {props.items.map((item: any, index: number) => {
                    return (
                        <Grid key={'menu-item-' + index} container spacing={2} sx={props.styles || {}}>

                            <ComponentRenderer components={[
                                {
                                    ...item,
                                    itemStyles: props.itemStyles,
                                    activeItemStyles: props.activeItemStyles
                                }
                            ]} />
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
}

export default MenuDefault;
