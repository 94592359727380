
import React, { useEffect } from 'react';
import ComponentRenderer from 'src/components/ComponentRenderer';
import useComponentState from 'src/hooks/state/useComponentState';
import {resolveComponentData} from "src/utils";
import { updateDocumentTitle} from 'src/utils';
import BackdropDefault from 'src/components/material/backdrop/default';
import { useDispatch, useSelector } from 'react-redux';
import { setViewData } from 'src/state/viewData/viewDataSlice';

function Screen({ screenId }: { screenId: string }) {

    const viewData = useSelector((state: any) => state.viewData);
    const dispatch = useDispatch();

    // eslint-disable-next-line 
    const [componentState, updateComponentState] = useComponentState(screenId, {
        ...viewData?.variables
    });
    
    useEffect(() => {
        const loadJson = async () => {
            try {
                const data = await import(`../data/screens/${screenId}.json`);
                dispatch(setViewData(resolveComponentData(data, ['layout'])));
                data.variables && updateComponentState(data.variables);
            } catch (error) {
                console.error('Error loading JSON file:', error);
            }
        };
        loadJson();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screenId]);
    
    if(viewData === null) return null;

    updateDocumentTitle(viewData.title);

    return (
        <>
            <ComponentRenderer components={viewData.layout.components} />
            <BackdropDefault />
        </>
    );
}

export default Screen;