
import AbstractDataHandler from './AbstractDataHandler';
import { Context, FlowContextData } from '../ActionFlow/Context';
import { DataAndReturnPathType } from './AbstractDataHandler';
/**
 * Context handler to store and retrieve context data
 */
class ContextDataHandler implements AbstractDataHandler {

    private context: Context;

    constructor(context: Context) {
        this.context = context;
    }

    // Store action data
    setActionData(actionId: string, data: any): void {
        this.context.setData(`action_${actionId}`, data);
    }

    // Get previous action data
    getActionData(actionId: string): any {
        return this.context.getData(`action_${actionId}`);
    }

    setCurrentAction(actionId: string): void {
        this.context.updateMetadata({ currentAction: actionId });
    }

    // Resolve action error
    setStatusAsError(errorMessage?: string): void {
        this.context.updateMetadata({
            status: "failed",
            lastError: errorMessage || 'Generic Action failed error'
        });
    }

    //Resolve action completion
    setStatusAsCompleted(): void {
        this.context.updateMetadata({ status: 'completed' });
    }

    // Get context data
    getContextData(): FlowContextData {
        return this.context.getAllData();
    }

    getDataAndRestPath(path: string): DataAndReturnPathType {
        const [source, id, ...restParts] = path.split('.');

        if(source === 'context' && id) {
            const data = this.getActionData(id);
            const restPath = restParts.join('.');
            return {
                data,
                restPath
            };
        }

        return {
            data: undefined,
            restPath: path
        };
    }
}

export default ContextDataHandler;