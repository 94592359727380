import store from "../state/store";
import { setState } from "../state/componentState/componentStateSlice";
import Executor from 'src/app/ActionFlow/Executor';
import { resolveContextData, setGlobalState as setGlobalStateUtil } from 'src/utils';
import _ from 'lodash';
import { executeAction } from 'src/utils/actionHanlders';
import { Operators } from 'src/app/operators';
import { componentRefs } from "src/hooks/state/useComponentRef";

const LogConsole = (config: any, executor?: Executor) => {

    if (executor) config = resolveContextData(config, executor?.getHandler());
    console.log(config.message);
}

const ShowToast = (config: any, executor?: Executor) => {
    if (executor) config = resolveContextData(config, executor?.getHandler());
    alert(config.message);
}

const ExitFlow = (config: any, executor?: Executor) => {
    throw new Error(config.message);
}

const SetComponentState = (config: any, executor?: Executor) => {

    if (!config.componentId) return;

    if (executor) config = resolveContextData(config, executor?.getHandler());

    store.dispatch(setState({
        id: config.componentId,
        data: config.data
    }));
}

const LoopOver = (config: any, executor?: Executor) => {

    if (executor) config = resolveContextData(config, executor?.getHandler(), {
        skipKeys: ['forEachItem']
    });

    const data = config.data;

    if (!data || (typeof data !== 'object' && !Array.isArray(data))) {
        throw new Error('Data is not iterable');
    }

    const entries = Array.isArray(data) ? data.entries() : Object.entries(data);

    if (!config.forEachItem) {
        return;
    }

    for (const [key, item] of entries) {

        if (executor) {
            executor.getHandler().setActionData(config.id, {
                currentItem: item,
                currentItemKey: key
            });
        }

        if (config.breakCondition) {
            const breakCondition = resolveContextData(config.breakCondition, executor?.getHandler());
            // eslint-disable-next-line
            if (new Function('Operators', `return ${breakCondition};`)(Operators)) {
                break;
            }
        }

        if (config.continueCondition) {
            const continueCondition = resolveContextData(config.continueCondition, executor?.getHandler());
            // eslint-disable-next-line
            if (new Function('Operators', `return ${continueCondition};`)(Operators)) {
                break;
            }
        }

        _.each(config.forEachItem, (action: any) => {
            executeAction(action, executor);
        });
    }
}

const SetGlobalState = (config: any, executor?: Executor) => {

    if (executor) config = resolveContextData(config, executor?.getHandler());

    if(!config.type && !config.data) {
        throw new Error('Type and data are required');
    }

    try{
        setGlobalStateUtil(config.type, config.data);
    }catch(e){
        throw new Error(e.message);
    }
}

const UpdateStyleAttributes = (config: any, executor?: Executor) => {
    if (executor) config = resolveContextData(config, executor?.getHandler());

    const componentId = config.componentId;

    if (!componentId || !componentRefs[componentId]) return;

    const ref = componentRefs[componentId];

    if (!ref.current) return;

    _.each(config.styles, (value: any, key:any) => {
        ref.current.style[key] = value;
    });
}


export { LogConsole, ShowToast, ExitFlow, SetComponentState, LoopOver, SetGlobalState, UpdateStyleAttributes };