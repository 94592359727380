
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Components } from './index';

function ComponentRenderer(components: any) {

    var theme = useSelector((state: any) => state.theme.value);
    var variant = 'default';

    const renderComponent = (component: any) => {
        if(component.theme){
            theme = component.theme
        }

        if(component.variant){
            variant = component.variant
        }

        const componentIdentifier = _.upperFirst(theme) + _.upperFirst(component.type) + _.upperFirst(variant);
        
        const LazyComponent = Components[componentIdentifier];
        if (!LazyComponent) return null;

        return (
            <LazyComponent key={component.id} {...component} />
        );
    };
    return (
        <>
            {_.map(components.components, renderComponent)}
        </>
    );
};

export default ComponentRenderer;