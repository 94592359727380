const len = (value: any) => {
    if (!value || value === '' || typeof value === 'number' || value instanceof Date || typeof value === 'boolean') {
        return 0;
    }

    if (typeof value === 'string') {
        return value.length;
    }

    if (Array.isArray(value)) {
        return value.length;
    }

    if (value && typeof value === 'object') {
        return Object.keys(value).length;
    }

    if (value instanceof Set) {
        return value.size;
    }

    if (value instanceof Map) {
        return value.size;
    }

    return 0;
}

const and = (...args: any) => {
    return args.every((item: any) => item);
}

const localStorage = window.localStorage;

export const Operators = {
    len, and, localStorage
}