const useResolveStyles = (styles: any, theme?: any) => {
    const resolvedStyles = {};

    Object.entries(styles).forEach(([key, value]) => {
        if (typeof value === 'object' && ['sm', 'md', 'lg', 'xl', 'xxl'].includes(key)) {

            resolvedStyles[theme.breakpoints.up(key)] = value;
        } else {

            resolvedStyles[key] = value;
        }
    });

    return resolvedStyles;
}

export default useResolveStyles;
