import { Checkbox as MuiCheckbox, FormControlLabel } from '@mui/material';
import { resolveComponentData } from "src/utils";
import useComponentState from "src/hooks/state/useComponentState";

function CheckboxDefault(props: any) {

    props = resolveComponentData(props);
    
    // eslint-disable-next-line
    const [componentState, updateComponentState] = useComponentState(props.id, {
        name: props.name,
        value: props.default || false,
        ...props.variables
    });

    if(undefined !== props.label) {
        return (
            <FormControlLabel required={props.required || false} control={<MuiCheckbox size="small" onChange={() => updateComponentState({ value: !componentState.value })} sx={props.styles || {}}/>} label={props.label} />
        );
    }

    return (
        <MuiCheckbox size="small" onChange={() => updateComponentState({ value: !componentState.value })} sx={props.styles || {}}/>
    );
}

export default CheckboxDefault