import { configureStore } from '@reduxjs/toolkit'
import themeReducer from './theme/themeSlice';
import componentStateReducer from './componentState/componentStateSlice';
import userReducer from './user/userSlice';
import viewDataReducer from './viewData/viewDataSlice';

export default configureStore({
    reducer: {
        theme: themeReducer,
        componentState: componentStateReducer,
        user: userReducer,
        viewData: viewDataReducer,
    },
})