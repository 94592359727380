import { useSpring, animated } from 'react-spring';
import { useState, useEffect } from 'react';

const BackgroundColorFillAnimation = ({ options, children }) => {

    const { backgroundColor, direction = 'left', initialTextColor = "#000000", textColor = '#ffffff', duration = 1000, delay = 0 } = options;

    const [reset, setReset] = useState(false);

    const getAnimationProps = () => {
        const baseConfig = {
            from: {
                backgroundSize: '0% 100%',
                backgroundPosition: '0% 0%',
                color: initialTextColor,
                ...options.initialStyles || {}
            },
            to: {
                backgroundSize: '100% 100%',
                backgroundPosition: '0% 0%',
                color: textColor,
                ...options.laterStyles || {}
            },
            config: { duration },
            delay,
            reset: reset,
            reverse: reset,
            onRest: () => setReset(false)
        };

        const directions = {
            left: { backgroundPosition: '0% 0%' },
            right: {
                from: { ...baseConfig.from, backgroundPosition: '100% 0%' },
                to: { ...baseConfig.to, backgroundPosition: '100% 0%' }
            },
            top: {
                from: { ...baseConfig.from, backgroundSize: '100% 0%' },
                to: { ...baseConfig.to, backgroundSize: '100% 100%' }
            },
            bottom: {
                from: { ...baseConfig.from, backgroundSize: '100% 0%', backgroundPosition: '0% 100%' },
                to: { ...baseConfig.to, backgroundSize: '100% 100%', backgroundPosition: '0% 100%' }
            },
            diagonal: {
                from: { ...baseConfig.from, backgroundSize: '0% 0%' },
                to: { ...baseConfig.to, backgroundSize: '100% 100%' }
            }
        };

        return {
            ...baseConfig,
            ...(directions[direction] || directions.left)
        };
    };

    // Effect to restart animation after a delay
    useEffect(() => {
        if(options.loop === undefined || options.loop === false || options.restartAfter === undefined) return;

        // const interval = setInterval(() => {
        //     setReset(true);
        // }, options.restartAfter);

        // return () => clearInterval(interval); // Cleanup interval on component unmount
    }, [options.loop, options.restartAfter]);

    const fillSpring = useSpring(getAnimationProps());

    return (
        <animated.div
            style={{
                ...fillSpring,
                backgroundImage: `linear-gradient(to ${direction === 'diagonal' ? 'bottom right' : direction}, ${backgroundColor}, ${backgroundColor})`,
                backgroundRepeat: 'no-repeat'
            }}
        >
            {children}
        </animated.div>
    );
}

export default BackgroundColorFillAnimation;