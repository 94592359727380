
import Grid from '@mui/material/Grid2';
import ComponentRenderer from "../../ComponentRenderer";
import AnimatedComponent from "src/animations/core/AnimatedComponent";
import useResolveStyles from 'src/hooks/useResolveStyles';
import { useTheme } from '@mui/material';
import { useEffect } from 'react';
import { eventsHandler } from "src/utils/actionHanlders"

function ColumnDefault(props: any) {
    const theme = useTheme();
    const styles = useResolveStyles(props.styles || {}, theme);

    (props.events && props.events.beforeRender) && eventsHandler(props.events.beforeRender);
    
    useEffect(() => {
        (props.events && props.events.afterRender) && eventsHandler(props.events.afterRender);
    }, []);

    if (undefined !== props.animation && props.animation.type !== 'typing') {
        const animationConfig = { ...props.animation };

        return (
            <AnimatedComponent config={animationConfig}>
                <Grid size={props.span} sx={styles || {}}>
                    <ComponentRenderer components={props.components} />
                </Grid>
            </AnimatedComponent>
        );
    }

    return (
        <Grid size={props.span} sx={styles || {}}  onMouseEnter={() => (props.events && props.events.onMouseEnter) && eventsHandler(props.events.onMouseEnter)} onMouseLeave={() => (props.events && props.events.onMouseLeave) && eventsHandler(props.events.onMouseLeave)}>
            <ComponentRenderer components={props.components} />
        </Grid>
    )
}

export default ColumnDefault;