import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const themeSlice = createSlice({
    name: 'theme',
    initialState: {
        value: null,
    },
    reducers: {
        setTheme: (state, action : PayloadAction<string>) => {
            state.value = action.payload
        },
    },
});

export const { setTheme } = themeSlice.actions;
export default themeSlice.reducer;