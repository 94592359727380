import { getGlobalState, resolveContextData } from 'src/utils';
import Executor from 'src/app/ActionFlow/Executor';


const getComponentId = (data: any, targetName: string) => {
    if (Array.isArray(data)) {
        for (const item of data) {
            const result = getComponentId(item, targetName);
            if (result) return result;
        }
    } else if (typeof data === 'object' && data !== null) {
        if (data.name === targetName) {
            return data.id;
        }

        for (const key in data) {
            const result = getComponentId(data[key], targetName);
            if (result) return result;
        }
    }
    return null;
}

const GetComponentIdByName = (config: any, executor?: Executor) => {
    if (executor) config = resolveContextData(config, executor?.getHandler());

    const viewData = getGlobalState('viewData');

    if(!viewData) {
        return;
    }

    const componentId = getComponentId(viewData.layout, config.componentName);

    if (executor) {
        executor.getHandler().setActionData(config.id, {
            componentId
        });
    }

    return componentId;
}

export default GetComponentIdByName;