import { TextField } from "@mui/material";
import useComponentState from "src/hooks/state/useComponentState";
import {eventsHandler} from "src/utils/actionHanlders";
import ValidateForm from "src/actions/ValidateInputs";
import {resolveComponentData} from "src/utils";

function TextInputDefault(props: any) {
    props = resolveComponentData(props);

    const [componentState, updateComponentState] = useComponentState(props.id, {
        value: props.default || '',
        label: props.label,
        name: props.name,
        validationError: false,
        errorMessage: '',
        required: props.required || false,
        validation: props.validation || {},
        disabled: props.disabled || false,
        ...props.varibles
    });

    const handleChange = (e: any) => {
        const value = e.target.value;
        updateComponentState({ value });

        if(props.liveValidation) {
            ValidateForm({
                components: [props.id]
            });
        }

        (props.events && props.events.onChange) && eventsHandler(props.events.onChange);
    }

    const handleBlur = () => {
        (props.events && props.events.onBlur) && eventsHandler(props.events.onBlur);
    }

    return (
        <TextField
            label={componentState.label}
            required={componentState.required || false}
            variant="outlined"
            type={props.inputType || 'text'}
            value={componentState.value}
            onChange={handleChange}
            onBlur={handleBlur}
            multiline={props.multiline || false}
            rows={props.rows || 1}
            fullWidth={props.fullWidth || true}
            size="small"
            disabled={componentState.disabled || false}
            error={componentState.validationError || false}
            helperText={componentState.validationError ? componentState.errorMessage : (props.helperText || '')}
        />
    );
}

export default TextInputDefault