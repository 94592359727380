import { setComponentState } from "src/utils";
import { setTheme } from "./theme/themeSlice";
import { setAuthenticated, setToken, setUserData, updateUserData } from "./user/userSlice";
import { setViewData } from "./viewData/viewDataSlice";

export const StateActions = {
    setUserData,
    updateUserData,
    setToken,
    setAuthenticated,
    setTheme,
    setViewData
};