import Executor from 'src/app/ActionFlow/Executor';
import _ from 'lodash';
import { Operators } from 'src/app/operators';
import { executeAction } from 'src/utils/actionHanlders';
import { resolveContextData } from 'src/utils';

const ExecuteCondition = (config: any, executor?: Executor) => {
    
    var success = false;

    config.condition = resolveContextData(config.condition, executor?.getHandler(), {
        skipKeys : ['onSuccess', 'onFailure']
    });

    // eslint-disable-next-line
    success = new Function('Operators',`return ${config.condition};`)(Operators);

    if(executor){
        executor.getHandler().setActionData(config.id, {
            success
        });
    }

    if (success && config.onSuccess) {
        _.each(config.onSuccess, (action: any) => {
            executeAction(action, executor);
        });
    }

    if(!success && config.onFailure) {
        _.each(config.onFailure, (action: any) => {
            executeAction(action, executor);
        });
    }

    return success;
}

export default ExecuteCondition;