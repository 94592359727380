import { useSpring, animated } from "react-spring";

function SlideAnimation({ options, children }) {

    const direction = options.direction || 'left';

    const getSlideTransform = (direction: string) => {
        switch (direction) {
            case 'top':
                return 'translateY(-100%)';
            case 'bottom':
                return 'translateY(100%)';
            case 'left':
                return 'translateX(-100%)';
            case 'right':
                return 'translateX(100%)';
            default:
                return 'translateX(0)';
        }
    };

    const slideSpring = useSpring({
        from: { transform: getSlideTransform(direction) },
        to: { transform: 'translate(0, 0)' },
        delay: options.preDelay || 0,
        config: { 
            duration : options.duration || 500, // Animation duration
            tension: 220, // Control the speed of spring (smoothness)
            friction: 30,  // Control the resistance (less friction = faster motion)
            clamp: true, // Prevent any bouncing back to original position
        },
    });

    return (
        <animated.div style={slideSpring}>
            {children}
        </animated.div>
    );
}

export default SlideAnimation;