
import { useEffect, useState } from 'react';

const useAsset = (path: string) => {

    const [asset, setAsset] = useState(null);

    useEffect(() => {
        const loadAsset = async () => {
            try {
                 if (path.startsWith('http')) {
                    setAsset(path);
                } else {
                    // Import local asset
                    const assetModule = await import(`/src/data/${path}`);
                    setAsset(assetModule.default);
                }
            } catch (error) {
                console.error('Error loading Asset file:', error);
            }
        };
        loadAsset();
    }, [path]);

    return asset;
};

export default useAsset;