import MaterialButtonDefault from "./material/button/default";
import MaterialButtonShadow from "./material/button/shadow";
import MaterialFormDefault from "./material/form/default";
import MaterialImageDefault from "./material/image/default";
import MaterialSectionDefault from "./material/section/default";
import MaterialTextDefault from "./material/text/default";
import MaterialTextInputDefault from "./material/textInput/default";
import MaterialCheckboxDefault from "./material/checkbox/default";
import MaterialContainerDefault from "./material/container/default";
import MaterialListingDefault from "./material/listing/default";
import MaterialMenuDefault from "./material/menu/default";
import MaterialMenuLinkDefault from "./material/menuLink/default";
import MaterialSubMenuDefault from "./material/subMenu/default";
import MaterialIframeDefault from "./material/iframe/default";

import BootstrapSearchDefault from "./bootstrap/search/default";
import BootstrapTextInputDefault from "./bootstrap/textInput/default";
import BootstrapSectionDefault from "./bootstrap/section/default";

export const Components = {
    MaterialButtonDefault,
    MaterialButtonShadow,
    MaterialFormDefault,
    MaterialImageDefault,
    MaterialSectionDefault,
    MaterialTextDefault,
    MaterialTextInputDefault,
    MaterialCheckboxDefault,
    MaterialContainerDefault,
    MaterialListingDefault,
    MaterialMenuDefault,
    MaterialMenuLinkDefault,
    MaterialSubMenuDefault,
    MaterialIframeDefault,

    // Bootstrap Components
    BootstrapSearchDefault,
    BootstrapTextInputDefault,
    BootstrapSectionDefault
}