
import { Box } from '@mui/material';
import { resolveComponentData } from "src/utils";
import useAsset from "src/hooks/useAsset";
import AnimatedComponent from 'src/animations/core/AnimatedComponent';

const ImageDefault = (props: any) => {
    const asset = useAsset(props.src);

    props = resolveComponentData(props, ['src']);

    if (asset === null) return null;


    if (undefined !== props.animation && props.animation.type !== 'typing') {
        const animationConfig = { ...props.animation };

        return (
            <AnimatedComponent config={animationConfig}>
                <Box
                    component="img"
                    sx={props.styles || {}}
                    alt={props.alt || 'Image'}
                    src={asset}
                />
            </AnimatedComponent>
        );
    }

    return (
        <Box
            component="img"
            sx={props.styles || {}}
            alt={props.alt || 'Image'}
            src={asset}
        />
    );
};

export default ImageDefault;

