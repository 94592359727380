const registerCustomValidators = (Validator: any) => {
    
    //Contains
    Validator.register(
        'contains',
        (value:string, substring:string) => value.includes(substring),
        'The :attribute must contain ":contains"'
    );

    //Phone
    Validator.register(
        'phone',
        (value: string) => {
            const regex = /^\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;
            return regex.test(value);
        },
        'The :attribute must be a valid phone number'
    );
}

export default registerCustomValidators;