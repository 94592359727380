
import { executeAction } from 'src/utils/actionHanlders';
import { Context } from './Context';
import ContextDataHandler from '../DataHandlers/ContextDataHandler';
import ContextManager from './ContextManager';

class Executor {

    private flowId: string;
    private context: Context;
    private handler: ContextDataHandler;

    constructor(flowId: string) {
        this.flowId = flowId;

        const contextManager = ContextManager.getInstance();
        this.context = contextManager.createContext(flowId);
        this.handler = new ContextDataHandler(this.context);
    }

    public async executeFlow() {

        try {
            var actionFlow = await import(`src/data/actionFlows/${this.flowId}.json`);

            if (!actionFlow.actions) {
                return;
            }

            if (actionFlow.async === false) {
                // Execute actions sequentially
                for (const action of actionFlow.actions) {
                    try {
                        await executeAction(action, this);
                    } catch (error) {
                        this.handler.setStatusAsError('Error while executing flow. ' + error);
                        console.error('Error while executing flow. ', error);
                        break;
                    }
                }
            } else {
                // Execute actions concurrently
                await Promise.all(actionFlow.actions.map(async (action) => {
                    try {
                        await executeAction(action, this);
                    } catch (error) {
                        this.handler.setStatusAsError('Error while executing flow. ' + error);
                        console.error('Error while executing flow. ', error);
                        throw error; // Ensure to stop further execution in case of error
                    }
                }));
            }

        } catch (error) {
            this.handler.setStatusAsError('Error while executing flow. ' + error);
            console.error('Error while executing flow. ', error);
        }

        return this.context.getContextId();
    }

    public getFlowId(): string {
        return this.flowId;
    }

    public getContext(): Context {
        return this.context;
    }

    public getHandler(): ContextDataHandler {
        return this.handler;
    }
}

export default Executor;