import { createContext, useMemo} from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { ThemeProvider as BootstrapThemeProvider } from 'react-bootstrap';
import { useDispatch } from 'react-redux'
import material from '../themes/material';
import bootstrap from '../themes/bootstrap';
import projectConfig from '../data/main.json';
import { setTheme } from 'src/state/theme/themeSlice';
import useAsset from 'src/hooks/useAsset';
import { updateFavicon } from 'src/utils';


const ThemeContext = createContext(undefined);

const themeType = projectConfig.theme;
var GlobalStyle = null;

function buildTheme() {
    if (themeType === 'material') {
        return material(projectConfig);
    } else if (themeType === 'bootstrap') {
        GlobalStyle = bootstrap(projectConfig);
        return {};
    }
}

const ThemeProvider = ({ children}) => {
    const theme = useMemo(() => buildTheme(), []);
    const dispatch = useDispatch();
    dispatch(setTheme(themeType));

    const favicon = useAsset(projectConfig.favicon);

    if (favicon) {
        updateFavicon(favicon);
    }
    
    return (
        <ThemeContext.Provider value={theme}>
            {themeType === 'material' ? (
                <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
            ) : (
                <BootstrapThemeProvider theme={theme}>
                    <GlobalStyle/>
                    {children}
                </BootstrapThemeProvider>
            )}
        </ThemeContext.Provider>
    );
};

export default ThemeProvider;
