import store from "../state/store";
import { setState } from "../state/componentState/componentStateSlice";
import AbstractDataHandler from 'src/app/DataHandlers/AbstractDataHandler';
import ComponentDataHandler from 'src/app/DataHandlers/ComponentDataHandler';
import DataResolver, { ResolverOptions } from 'src/app/DataResolver';
import { StateActions } from "src/state/actions";

const updateFavicon = (faviconURL: string): void => {
    const link: HTMLLinkElement | null = document.querySelector("link[rel~='icon']");

    if (!link) {
        const newLink: HTMLLinkElement = document.createElement("link");
        newLink.rel = "icon";
        newLink.href = faviconURL;
        document.head.appendChild(newLink);
    } else {
        link.href = faviconURL;
    }
};

const updateDocumentTitle = (title: string): void => {
    document.title = title;
};

const getGlobalState = (key: string) => {
    const state = store.getState();
    return state[key] || undefined;
}

const setGlobalState = (type: string, data: any) => {

    if (!StateActions[type]) {
        throw new Error(`Action ${type} not found`);
    }

    store.dispatch(StateActions[type](data));
}

const getComponentState = (id: string) => {
    const state = store.getState();
    return state.componentState[id] || undefined;
}

const setComponentState = (id: string, data: any) => {
    store.dispatch(setState({ id, data }));
}

const resolveContextData = (data: any, handler: AbstractDataHandler | null, options: ResolverOptions = {}) => {
    if (!handler) return data;

    const resolver = new DataResolver(handler, options);
    return resolver.resolveData(data);
}

const resolveComponentData = (data: any, ignoredKeys = []) => {

    const DEFAULT_IGNORED_KEYS = ['type', 'id', 'name', 'styles', 'events', 'theme', 'animation', 'validation', 'variant'];

    ignoredKeys = [...DEFAULT_IGNORED_KEYS, ...ignoredKeys];

    const handler = new ComponentDataHandler();

    const resolver = new DataResolver(handler, {
        skipKeys: ignoredKeys
    });

    return resolver.resolveData(data);
}

export {
    updateFavicon,
    updateDocumentTitle,
    getGlobalState,
    getComponentState,
    setComponentState,
    resolveContextData,
    resolveComponentData,
    setGlobalState
};
