import { createRef, useState, useEffect, useRef } from 'react';
import { getShapeCoordinates, getShape } from './shapes';

const SpreadAnimation = ({
    options,
    children
}) => {
    const [positions, setPositions] = useState([]);
    const [containerPosition, setContainerPosition] = useState({ x: 0, y: 0 });
    const [isReady, setIsReady] = useState(false);
    const containerRef = useRef(null);
    const childRefs = useRef([]);

    const {
        shape,
        width,
        height,
        debug = false
    } = options;

    useEffect(() => {
        const updateContainerPosition = () => {
            if (containerRef.current) {
                const rect = containerRef.current.getBoundingClientRect();
                setContainerPosition({
                    x: rect.left + window.scrollX,
                    y: rect.top + window.scrollY
                });
            }
        };

        updateContainerPosition();
        window.addEventListener('resize', updateContainerPosition);
        window.addEventListener('scroll', updateContainerPosition);

        return () => {
            window.removeEventListener('resize', updateContainerPosition);
            window.removeEventListener('scroll', updateContainerPosition);
        };
    }, []);

    const centerX = containerPosition.x + width / 2;
    const centerY = containerPosition.y + height / 2;

    useEffect(() => {
        childRefs.current = Array(children.length)
            .fill(null)
            .map((_, index) => childRefs.current[index] || createRef());
    }, [children]);

    useEffect(() => {
        if (!containerPosition.x && !containerPosition.y) return;

        requestAnimationFrame(() => {
            // Initialize all items at center
            setPositions(children.map(() => ({
                x: centerX,
                y: centerY,
                scale: 0,
                opacity: 0,
                rotation: 0
            })));

            // Measure heights after elements are rendered
            const heights = childRefs.current.map(ref => {
                if (ref.current) {
                    const element = ref.current.querySelector('div');
                    return element ? element.offsetHeight : 0;
                }
                return 0;
            });

            // Only proceed if we have valid heights
            if (heights.some(h => h > 0)) {
                const shapePositions = calculateShapeCoordinates();

                // Add slight delay for initial animation
                setTimeout(() => {
                    setPositions(shapePositions.map((pos, index) => ({
                        ...pos,
                        y: pos.y - (heights[index] / 2),
                        scale: 1,
                        opacity: 1
                    })));
                    setIsReady(true);
                }, 300);
            }
        });

    }, [centerX, centerY, width, height]);

    const calculateShapeCoordinates = () => {
        const getShapeCoordinatesFunction = getShapeCoordinates(options.shape);

        if (!getShapeCoordinatesFunction) {
            console.error(`Invalid shape ${shape}`);
            return [];
        }

        return getShapeCoordinatesFunction({
            posX: containerPosition.x,
            posY: containerPosition.y,
            n: children.length,
            ...options
        });
    }

    const generatePathString = () => {
        const getShapeFunction = getShape(options.shape);

        if (!getShapeFunction) {
            console.error(`Invalid shape ${shape}`);
            return '';
        }

        return getShapeFunction({
            posX: containerPosition.x,
            posY: containerPosition.y,
            width,
            height
        });
    };

    const renderDebugPath = () => {
        if (!debug || !isReady) return null;

        return (
            <>
                {/* Draw points at each position */}
                {positions.map((pos, index) => {
                    const relativeX = pos.x - containerPosition.x;
                    const relativeY = pos.y;

                    return (
                        <div
                            key={`debug-point-${index}`}
                            style={{
                                position: 'absolute',
                                width: '6px',
                                height: '6px',
                                backgroundColor: 'blue',
                                borderRadius: '50%',
                                transform: `translate(${relativeX}px, ${relativeY}px)`,
                                zIndex: 1000,
                            }}
                        />
                    );
                })}
                {/* Draw path */}
                <svg
                    width={width}
                    height={height}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 1000
                    }}
                >
                    <path
                        d={generatePathString()}
                        fill="none"
                        stroke="blue"
                        strokeWidth="2"
                    />
                </svg>
            </>
        );
    };

    return (
        <div
            ref={containerRef}
            style={{
                position: 'relative',
                overflow: 'hidden',
                border: debug ? '2px solid blue' : 'none',
                width: width,
                height: height,
                margin: '0 auto',
                transform: `translate(0px, 0px)`
            }}
        >
            {renderDebugPath()}
            {children.map((item: any, index: number) => {

                const position = positions[index] || {
                    x: centerX,
                    y: centerY,
                    scale: 0,
                    opacity: 0,
                    rotation: 0
                };
                const relativeX = position.x - containerPosition.x;
                const relativeY = position.y;

                return (
                    <div
                        ref={childRefs.current[index]}
                        style={{
                            position: 'absolute',
                            transition: 'all 800ms cubic-bezier(0.4, 0, 0.2, 1)',
                            transform: `translate(${relativeX}px, ${relativeY}px) 
                                      rotate(${position.rotation}deg) 
                                      scale(${position.scale || 1})`,
                            opacity: position.opacity === 0 ? 0 : 1,
                        }}
                        key={index}
                    >
                        <div style={{
                            width: 'max-content',
                            position: 'absolute',
                            transform: 'translate(-50%, -50%)'
                        }}>
                            {item}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default SpreadAnimation;