import {generateCircleCoords as CircleCords, generateCirclePath as CircleShape} from "./circle";
import {generateShapeCoords as EllipseCords, generateShapePath as EllipseShape} from "./ellipse";
import semiCircle from "./semiCircle";

const cords = {
    ellipse: EllipseCords,
    circle: CircleCords,
    semiCircle: semiCircle
};

const shapes = {
    ellipse: EllipseShape,
    circle: CircleShape,
    semiCircle: semiCircle
};

export const getShapeCoordinates = (name: string) => {
    return cords[name] || null;
}

export const getShape = (name: string) => {
    return shapes[name] || null;
};