import AbstractDataHandler from "./AbstractDataHandler";
import { DataAndReturnPathType } from "./AbstractDataHandler";
import mainConfig from 'src/data/main.json';
import { memoize } from 'lodash';
import { getComponentState, getGlobalState } from "src/utils";
import routesConfig from 'src/data/routes.json';

class ComponentDataHandler implements AbstractDataHandler {

    loadScreenConfig = memoize((screenName: string) => {
        try {
            return require(`src/data/screens/${screenName}.json`);
        } catch (error) {
            console.error(`Error loading screen config for ${screenName}:`, error);
            return null;
        }
    })

    getDataAndRestPath(path: string): DataAndReturnPathType {
        const [type, ...pathParts] = path.split('.');
        const newPath = pathParts.join('.');

        if(type === 'data') {
            const [source, ...restParts] = newPath.split('.');
            const restPath = restParts.join('.');

            if (source === 'main') {
                return {
                    data: mainConfig,
                    restPath
                };

            } else if (source === 'routes') {
                return {
                    data: routesConfig,
                    restPath
                };

            } else if (source === 'screens') {
                const [screenName, ...screenPath] = restPath.split('.');
                
                const screenConfig = this.loadScreenConfig(screenName);

                return {
                    data: screenConfig ?? undefined,
                    restPath: screenPath.join('.')
                };
            }
        }else if(type === 'state') {
            const [source, id, ...restParts] = newPath.split('.');
            const restPath = restParts.join('.');

            if (source === 'component') {
                const state = getComponentState(id);
                return {
                    data: state,
                    restPath
                }
            }else if (source === 'global') {
                const state = getGlobalState(id);

                return {
                    data: state,
                    restPath
                }
            }
        }

        return {
            data: undefined,
            restPath: path
        }
    }
}

export default ComponentDataHandler;