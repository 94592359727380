type FlowContextData = {
    [key: string]: any;
};

type FlowMetadata = {
    flowId: string;
    startTime: Date;
    status: 'active' | 'completed' | 'failed';
    currentAction?: string;
    lastError?: string;
};

/**
 * Context class to share data between actions
 */
class Context {

    private contextId: string;
    private data: FlowContextData;
    private metadata: FlowMetadata;

    constructor(flowId: string) {
        this.contextId = `flow_${flowId}_${Date.now()}`;
        this.data = {};
        this.metadata = {
            flowId,
            startTime: new Date(),
            status: 'active'
        };
    }

    setData(key: string, value: any): void {
        this.data[key] = value;
    }

    getData(key: string): any {
        return this.data[key];
    }

    getAllData(): FlowContextData {
        return { ...this.data };
    }

    updateMetadata(updates: Partial<FlowMetadata>): void {
        this.metadata = { ...this.metadata, ...updates };
    }

    getContextId(): string {
        return this.contextId;
    }

    getMetadata(): FlowMetadata {
        return { ...this.metadata };
    }
}

export { Context };
export type { FlowContextData, FlowMetadata };