import { createSlice } from '@reduxjs/toolkit'

const viewDataSlice = createSlice({
    name: 'viewData',
    initialState: null,
    reducers: {
        setViewData: (state, action:any) => {
            state = action.payload;
            return state;
        },
    },
});

export const { setViewData } = viewDataSlice.actions;

export default viewDataSlice.reducer;