const IframeDefault = (props: any) => {

    return (
        <iframe
            src={props.src}
            title={props.name}
            width={props.width || "560"}
            height={props.height || "315"}
        />
    );
}

export default IframeDefault;