import { Context, FlowContextData } from './Context';

/**
 * Context manager class to have a central place to store and retrieve context data
 */
class ContextManager {

    private static instance: ContextManager;
    private activeContexts: Map<string, Context>;
    private subscribers: Map<string, Set<(data: FlowContextData) => void>>;

    private constructor() {
        this.activeContexts = new Map();
        this.subscribers = new Map();
    }

    // Singleton pattern
    static getInstance(): ContextManager {
        if (!ContextManager.instance) {
            ContextManager.instance = new ContextManager();
        }
        return ContextManager.instance;
    }

    // Create new flow context
    createContext(flowId: string): Context {
        const context = new Context(flowId);
        this.activeContexts.set(context.getContextId(), context);
        return context;
    }

    // Get existing flow context
    getContext(contextId: string): Context | undefined {
        return this.activeContexts.get(contextId);
    }

    // Get all active contexts for a specific flow
    getContextsByFlowId(flowId: string): Context[] {
        return Array.from(this.activeContexts.values())
            .filter(context => context.getMetadata().flowId === flowId);
    }

    // Remove flow context
    removeContext(contextId: string): void {
        this.activeContexts.delete(contextId);
        this.subscribers.delete(contextId);
    }

    // Subscribe to context changes
    subscribe(contextId: string, callback: (data: FlowContextData) => void): () => void {
        if (!this.subscribers.has(contextId)) {
            this.subscribers.set(contextId, new Set());
        }
        this.subscribers.get(contextId)?.add(callback);

        // Return unsubscribe function
        return () => {
            this.subscribers.get(contextId)?.delete(callback);
        };
    }

    // Notify subscribers of changes
    private notifySubscribers(contextId: string, data: FlowContextData): void {
        this.subscribers.get(contextId)?.forEach(callback => callback(data));
    }
}

export default ContextManager;
