import Executor from 'src/app/ActionFlow/Executor';
import { getComponentState, setComponentState } from 'src/utils';
import _ from 'lodash';
import * as Validator from 'validatorjs';
import registerCustomValidators from 'src/app/validators/validators';
import { executeAction } from 'src/utils/actionHanlders';

const getComponents = (config: any) => {

    if (config.formId !== undefined) {
        const formState = getComponentState(config.formId);

        if (formState === undefined) {
            throw new Error('Form state is required');
        }

        const components = formState.components;

        return components;

    } else if (config.components !== undefined) {
        return config.components;
    }

    return undefined;
}

const constructValidationRules = (components: any) => {
    const data = {};
    const rules = {};
    const customMessages = {};

    _.each(components, (componentId: string) => {
        const componentState = getComponentState(componentId);

        if (componentState === undefined) return;

        const { name, value, validation } = componentState;

        if (value === undefined) return;
        if (name === undefined) return;


        data[name] = value;

        let validationRules = '';

        if (componentState.required) {
            validationRules += 'required';
        }

        if (validation !== undefined) {
            if (validation.rules !== undefined) {
                validationRules += '|' + validation.rules;
            }

            if (validation.messages !== undefined) {
                for (const [rule, message] of Object.entries(validation.messages)) {
                    customMessages[`${rule}.${name}`] = message;
                }
            }
        }

        if (validationRules !== '') {
            rules[name] = validationRules;
        }

    });

    return { data, rules, customMessages };
}

/**
 * Action to validate a form or set of input components
 * 
 * Performs Validations using validatorjs
 * @link https://www.npmjs.com/package/validatorjs
 * 
 * @param config: 
 * {
 *  formId: string => id of the form to validate (not required when using components)
 *  components: []  => array of input component ids to validate (not required when using formId)
 * }
 * @param executor: Executor
 * @returns boolean
 */
const ValidateInputs = (config: any, executor?: Executor) => {
    if (config.formId === undefined && config.components === undefined) {
        throw new Error('Form ID or components are required');
    }

    const components = getComponents(config);

    if (components === undefined) {
        throw new Error('Components are required');
    }

    registerCustomValidators(Validator);

    let isValid = true;

    const { data, rules, customMessages } = constructValidationRules(components);

    const validator = new Validator(data, rules, customMessages);

    if (validator.fails()) {
        isValid = false;

        _.each(components, (componentId: string) => {
            const componentState = getComponentState(componentId);

            if (componentState === undefined) return;
            const name = componentState.name;

            if (name === undefined) return;

            if (validator.errors.has(name)) {
                const error = validator.errors.first(name);
                setComponentState(componentId, { validationError: true, errorMessage: error });
            }

            if(componentState.validationError === true && !validator.errors.has(name)) {
                setComponentState(componentId, { validationError: false, errorMessage: '' });
            }
        });
    }

    if(validator.passes()) {
        _.each(components, (componentId: string) => {
            const componentState = getComponentState(componentId);

            if (componentState === undefined) return;
            const name = componentState.name;

            if (name === undefined) return;

            setComponentState(componentId, { validationError: false, errorMessage: '' });
        });
    }

    if(executor){
        executor.getHandler().setActionData(config.id, {
            valid: isValid
        });
    }

    if (isValid && config.onSuccess) {
        _.each(config.onSuccess, (action: any) => {
            executeAction(action, executor);
        });
    }

    if(!isValid && config.onFailure) {
        _.each(config.onFailure, (action: any) => {
            executeAction(action, executor);
        });
    }

    return isValid;
}

export default ValidateInputs;