
import ThemeProvider from '../providers/ThemeProvider';
import Screen from './Screen';
import projectConfig from '../data/main.json';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { setNavigator } from 'src/actions/Navigate';

function App({ screenId }: { screenId: string | null }) {

    if (screenId === null) screenId = projectConfig.defaultScreen;
    const navigate = useNavigate();

    // Initialize navigation (choose one approach)
    useEffect(() => {
        // Option 1
        setNavigator(navigate);

    }, [navigate]);

    return (
        <ThemeProvider>
            <Screen screenId={screenId} />
        </ThemeProvider>
    );
}

export default App;
