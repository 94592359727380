const generateCircleCoords = (dimensions: any) => {
    const {
        posX,
        posY,
        width: containerWidth,
        height: containerHeight,
        n,
        tiltAlongPath,
        spreadUniformly = false,
        distanceBetweenCords = 30
    } = dimensions;

    // Calculate radius based on the given width
    const radius = (containerWidth - 50) * 0.3;

    // Find center point of the container in actual coordinates
    const centerX = posX + containerWidth / 2;
    const centerY = posY + containerHeight / 2;

    const toScreenCoords = (x: number, y: number) => ({
        x: x + centerX,
        y: centerY - y
    });

    const points = [];

    for (let i = 0; i < n; i++) {
        // Calculate the current angle

        let angle: number;
        if (!spreadUniformly) {
            // Calculate the arc length for the first point
            const firstArcLength = distanceBetweenCords;

            // Convert arc length to angle (in radians)
            const angleStep = firstArcLength / radius;

            // Calculate total angle needed for all points with given distanceBetweenCords
            const totalAngleNeeded = angleStep * (n - 1);

            // Calculate the starting angle to center the points
            const startAngle = Math.PI / 2 + totalAngleNeeded / 2;
            angle = startAngle - (angleStep * i);
        } else {
            const t = (i / n) * 2 * Math.PI;
            const startAngle = Math.PI / 2;
            angle = startAngle - t;
        }

        const mathX = radius * Math.cos(angle);
        const mathY = radius * Math.sin(angle);

        const screenPos = toScreenCoords(mathX, mathY);

        if (!tiltAlongPath) {
            points.push({
                x: screenPos.x,
                y: screenPos.y,
                rotation: 0
            });
            continue;
        }

        // Calculate rotation angle using the derivative of parametric equations
        const dx = radius * Math.sin(angle);
        const dy = radius * Math.cos(angle);
        let rotation = Math.atan2(dy, dx);

        // Convert to degrees and adjust for screen coordinates
        rotation = (rotation * 180 / Math.PI);

        const isBottomHalf = screenPos.y > centerY;
        const isRightHalf = screenPos.x > centerX;

        if (isBottomHalf) {
            if (rotation < 0 && !isRightHalf) {
                rotation = 180 + rotation;
            } else if (rotation > 0 && isRightHalf) {
                rotation = rotation - 180;
            } else {
                rotation = 180 - rotation;
            }
        }

        points.push({
            x: screenPos.x,
            y: screenPos.y,
            rotation: rotation
        });
    }


    return points;
};

const generateCirclePath = (dimensions: any) => {
    const { posX, posY, width: containerWidth, height: containerHeight } = dimensions;

    // Calculate radius based on the given width
    const radius = (containerWidth - 50) * 0.3;

    const centerY = posY + containerHeight / 2;

    // Generate the path string for the circle
    return `M ${posX}, ${containerHeight / 2}
            a ${radius},${radius} 0 1,0 ${radius * 2},0
            a ${radius},${radius} 0 1,0 -${radius * 2},0`;
};

export { generateCircleCoords, generateCirclePath };