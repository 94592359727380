import { resolveComponentData } from "src/utils";
import Box from "@mui/material/Box";
import ComponentRenderer from "../../ComponentRenderer";
import { useEffect } from 'react';
import AnimatedComponent from "src/animations/core/AnimatedComponent";
import { eventsHandler } from "src/utils/actionHanlders";
import useComponentState from "src/hooks/state/useComponentState";
import useComponentRef from "src/hooks/state/useComponentRef";

function ContainerDefault(props: any) {
    props = resolveComponentData(props, ['components']);

    //eslint-disable-next-line
    const [componentState, updateComponentState] = useComponentState(props.id, {
        animation: props.animation || null
    });

    const ref = useComponentRef(props.id);

    (props.events && props.events.beforeRender) && eventsHandler(props.events.beforeRender);

    useEffect(() => {
        (props.events && props.events.afterRender) && eventsHandler(props.events.afterRender);
    }, []);

    if (null !== componentState.animation) {
        const animationConfig = { ...componentState.animation };

        if (animationConfig.type !== 'typing') {
            return (
                <AnimatedComponent config={animationConfig}>
                    <Box component={props.component || 'div'} sx={props.styles || {}}>
                        {props.components !== undefined && <ComponentRenderer components={props.components} />}
                    </Box>
                </AnimatedComponent>
            );
        }
    }

    return (
        <Box ref={ref} component={props.component || 'div'} sx={props.styles || {}}>
            {props.components !== undefined && <ComponentRenderer components={props.components} />}
        </Box>
    )
}

export default ContainerDefault;