import { createTheme } from '@mui/material/styles';


function buildTheme(projectConfig) {
    const themeConfig = projectConfig.styling;
    const themeOverrides = {
        palette: {
            ...(themeConfig.colorPrimary && {
                primary: {
                    main: themeConfig.colorPrimary,
                    ...(themeConfig.colorPrimaryDark && { dark: themeConfig.colorPrimaryDark }),
                    ...(themeConfig.colorPrimaryLight && { light: themeConfig.colorPrimaryLight }),
                    ...(themeConfig.colorPrimaryContrastText && { contrastText: themeConfig.colorPrimaryContrastText }),
                },
            }),
            ...(themeConfig.colorSecondary && {
                secondary: {
                    main: themeConfig.colorSecondary,
                    ...(themeConfig.colorSecondaryDark && { dark: themeConfig.colorSecondaryDark }),
                    ...(themeConfig.colorSecondaryLight && { light: themeConfig.colorSecondaryLight }),
                    ...(themeConfig.colorSecondaryContrastText && { contrastText: themeConfig.colorSecondaryContrastText }),
                },
            }),
            ...(themeConfig.colorSuccess && {
                success: {
                    main: themeConfig.colorSuccess,
                    ...(themeConfig.colorSuccessDark && { dark: themeConfig.colorSuccessDark }),
                    ...(themeConfig.colorSuccessLight && { light: themeConfig.colorSuccessLight }),
                    ...(themeConfig.colorSuccessContrastText && { contrastText: themeConfig.colorSuccessContrastText }),
                },
            }),
            ...(themeConfig.colorDanger && {
                error: {
                    main: themeConfig.colorDanger,
                    ...(themeConfig.colorDangerDark && { dark: themeConfig.colorDangerDark }),
                    ...(themeConfig.colorDangerLight && { light: themeConfig.colorDangerLight }),
                    ...(themeConfig.colorDangerContrastText && { contrastText: themeConfig.colorDangerContrastText }),
                },
            }),
            ...(themeConfig.colorWarning && {
                warning: {
                    main: themeConfig.colorWarning,
                    ...(themeConfig.colorWarningDark && { dark: themeConfig.colorWarningDark }),
                    ...(themeConfig.colorWarningLight && { light: themeConfig.colorWarningLight }),
                    ...(themeConfig.colorWarningContrastText && { contrastText: themeConfig.colorWarningContrastText }),
                },
            }),
            ...(themeConfig.colorInfo && {
                info: {
                    main: themeConfig.colorInfo,
                    ...(themeConfig.colorInfoDark && { dark: themeConfig.colorInfoDark }),
                    ...(themeConfig.colorInfoLight && { light: themeConfig.colorInfoLight }),
                    ...(themeConfig.colorInfoContrastText && { contrastText: themeConfig.colorInfoContrastText }),
                },
            }),
            ...(themeConfig.bodyBg || themeConfig.backgroundColor) && {
                background: {
                    ...(themeConfig.bodyBg && { default: themeConfig.bodyBg }),
                    ...(themeConfig.backgroundColor && { paper: themeConfig.backgroundColor }),
                },
            },
            ...(themeConfig.bodyColor || themeConfig.colorMuted) && {
                text: {
                    ...(themeConfig.bodyColor && { primary: themeConfig.bodyColor }),
                    ...(themeConfig.colorMuted && { secondary: themeConfig.colorMuted }),
                },
            },
        },
        typography: {
            ...(themeConfig.fontFamily && { fontFamily: themeConfig.fontFamily }),
            ...(themeConfig.fontSize && { fontSize: parseInt(themeConfig.fontSize, 10) }),
        },
    };

    return createTheme(themeOverrides);
    
};

export default buildTheme;