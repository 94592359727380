
import { Button, CircularProgress } from '@mui/material';
import { resolveComponentData } from "src/utils";
import AnimatedComponent from 'src/animations/core/AnimatedComponent';
import useComponentState from "src/hooks/state/useComponentState";
import { eventsHandler } from "src/utils/actionHanlders";
import { useTheme } from '@mui/material';
import useResolveStyles from 'src/hooks/useResolveStyles';
import { Link } from 'react-router-dom';

function ButtonDefault(props: any) {

    props = resolveComponentData(props);

    const theme = useTheme();
    const styles = useResolveStyles(props.styles || {}, theme);

    // eslint-disable-next-line
    const [componentState, updateComponentState] = useComponentState(props.id, {
        loading: props.loading || false,
        disable: props.disable || false
    });


    const handleClick = () => {
        (props.events && props.events.onClick) && eventsHandler(props.events.onClick);
    }


    if (undefined !== props.animation) {
        const animationConfig = { ...props.animation };


        if (animationConfig.type === 'typing') {
            animationConfig.options = { ...animationConfig.options, text: props.text };
        }

        return (
            <AnimatedComponent config={animationConfig}>
                <Button
                    sx={styles || {}}
                    onClick={handleClick}
                    variant={props.btnClass || 'contained'}
                    fullWidth={props.fullWidth || false}
                    disabled={componentState.disable || false}
                    type={props.btnType || 'button'}
                    startIcon={componentState.loading ? <CircularProgress color="inherit" size={20} /> : null}
                    component={props.href ? Link : 'button'}
                    to={props.href || null}
                >
                    {props.text}
                </Button>
            </AnimatedComponent>
        );
    }

    return (
        <Button
            sx={styles || {}}
            onClick={handleClick}
            variant={props.btnClass || 'contained'}
            fullWidth={props.fullWidth || false}
            disabled={componentState.disable || false}
            type={props.btnType || 'button'}
            startIcon={componentState.loading ? <CircularProgress color="inherit" size={20} /> : null}
            component={props.href ? Link : 'button'}
            to={props.href || null}
        >
            {props.text}
        </Button>
    );
}

export default ButtonDefault