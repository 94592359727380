import {
    SetComponentState,
    ShowToast,
    ExitFlow,
    LogConsole,
    LoopOver,
    SetGlobalState,
    UpdateStyleAttributes
} from './CommonActions';
import ExecuteCondition from './ExecuteCondition';
import GetComponentIdByName from './GetComponentIdByName';
import PerformApiCall from './PerformApiCall';
import ValidateInputs from './ValidateInputs';
import { Navigate } from './Navigate';
import ApplyAnimation from './ApplyAnimation';


export const actions = {
    SetComponentState,
    ShowToast,
    ExitFlow,
    LogConsole,
    PerformApiCall,
    ExecuteCondition,
    ValidateInputs,
    LoopOver,
    GetComponentIdByName,
    Navigate,
    SetGlobalState,
    ApplyAnimation,
    UpdateStyleAttributes
}