import useComponentState from "src/hooks/state/useComponentState";
import Grid from '@mui/material/Grid2';
import Box from "@mui/material/Box";
import { resolveComponentData } from "src/utils";
import _ from "lodash";
import AnimatedComponent from "src/animations/core/AnimatedComponent";
import ColumnDefault from "../column/default";


function SectionDefault(props: any) {

    props = resolveComponentData(props, ['columns']);

    // eslint-disable-next-line
    const [componentState, updateComponentState] = useComponentState(props.id, {
        ...props.variables
    });

    if(undefined !== props.animation && props.animation.type !== 'typing') {
        const animationConfig = { ...props.animation };

        return (
            <AnimatedComponent config={animationConfig}>
                <Box component={props.component || 'section'}>
                    <Grid container spacing={props.spacing || 2} sx={props.styles || {}}>
                        {_.map(props.columns, (column: any, index: number) => (
                            <ColumnDefault key={props.id + '-col-' + index} {...column} />
                        ))}
                    </Grid>
                </Box>
            </AnimatedComponent>
        );
    }

    return (
        <Box component={props.component || 'section'}>
            <Grid container spacing={props.spacing || 2} sx={props.styles || {}}>
                {_.map(props.columns, (column: any, index: number) => (
                    <ColumnDefault key={props.id + '-col-' + index} {...column} />
                ))}
            </Grid>
        </Box>
    );
}

export default SectionDefault;