
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setState, deleteState } from 'src/state/componentState/componentStateSlice';

const useComponentState = (id: string, initalValue = {}) => {
    const state = useSelector((state: any) => state.componentState);
    const dispatch = useDispatch();

    useEffect(() => {
        var data = initalValue;
        dispatch(setState({ id, data }));
        return () => {
            dispatch(deleteState({ id }));
        };
        // eslint-disable-next-line
    }, [id]);

    const updateState = (data: {}) => {
        dispatch(setState({ id, data }));
    };

    return [state[id] || initalValue, updateState];
};

export default useComponentState;